import React from 'react';
import './Pricing.css'

const PricingSection = () => {
    return (
        <section id="pricing-1790">
            <div className="cs-container">
                <div className="cs-content">
                    <div className="cs-flex">
                        <span className="cs-topper">Pricing</span>
                        <h2 className="cs-title">Choose Pricing Plans</h2>
                    </div>
                    {/* <a href="#" className="cs-button-solid">Book Appointment</a> */}
                </div>
                <div className="cs-wrapper">
                    <ul className="cs-ul">
                        <li className="cs-li">
                            <img
                                className="cs-icon"
                                aria-hidden="true"
                                loading="lazy"
                                decoding="async"
                                src="https://csimg.nyc3.cdn.digitaloceanspaces.com/Icons/mech-check.svg"
                                alt="checkmark"
                                width="24"
                                height="24"
                            />
                            On Page SEO Optimization
                        </li>
                        <li className="cs-li">
                            <img
                                className="cs-icon"
                                aria-hidden="true"
                                loading="lazy"
                                decoding="async"
                                src="https://csimg.nyc3.cdn.digitaloceanspaces.com/Icons/mech-check.svg"
                                alt="checkmark"
                                width="24"
                                height="24"
                            />
                            Performative, Mobile First Website
                        </li>
                        <li className="cs-li">
                            <img
                                className="cs-icon"
                                aria-hidden="true"
                                loading="lazy"
                                decoding="async"
                                src="https://csimg.nyc3.cdn.digitaloceanspaces.com/Icons/mech-check.svg"
                                alt="checkmark"
                                width="24"
                                height="24"
                            />
                            Money Back Guarantee
                        </li>
                        <li className="cs-li">
                            <img
                                className="cs-icon"
                                aria-hidden="true"
                                loading="lazy"
                                decoding="async"
                                src="https://csimg.nyc3.cdn.digitaloceanspaces.com/Icons/mech-check.svg"
                                alt="checkmark"
                                width="24"
                                height="24"
                            />
                            Review of Design Process
                        </li>
                        <li className="cs-li">
                            <img
                                className="cs-icon"
                                aria-hidden="true"
                                loading="lazy"
                                decoding="async"
                                src="https://csimg.nyc3.cdn.digitaloceanspaces.com/Icons/mech-check.svg"
                                alt="checkmark"
                                width="24"
                                height="24"
                            />
                            Google Business Profile Optimization
                        </li>
                        <li className="cs-li">
                            <img
                                className="cs-icon"
                                aria-hidden="true"
                                loading="lazy"
                                decoding="async"
                                src="https://csimg.nyc3.cdn.digitaloceanspaces.com/Icons/mech-check.svg"
                                alt="checkmark"
                                width="24"
                                height="24"
                            />
                            Unlimited Edits
                        </li>
                        <li className="cs-li">
                            <img
                                className="cs-icon"
                                aria-hidden="true"
                                loading="lazy"
                                decoding="async"
                                src="https://csimg.nyc3.cdn.digitaloceanspaces.com/Icons/mech-check.svg"
                                alt="checkmark"
                                width="24"
                                height="24"
                            />
                            Logo Design
                        </li>
                        <li className="cs-li">
                            <img
                                className="cs-icon"
                                aria-hidden="true"
                                loading="lazy"
                                decoding="async"
                                src="https://csimg.nyc3.cdn.digitaloceanspaces.com/Icons/mech-check.svg"
                                alt="checkmark"
                                width="24"
                                height="24"
                            />
                            Domain Services
                        </li>
                        <li className="cs-li">
                            <img
                                className="cs-icon"
                                aria-hidden="true"
                                loading="lazy"
                                decoding="async"
                                src="https://csimg.nyc3.cdn.digitaloceanspaces.com/Icons/mech-check.svg"
                                alt="checkmark"
                                width="24"
                                height="24"
                            />
                            Personal Digital Consultant
                        </li>
                    </ul>
                    <ul className="cs-card-group">
                        <li className="cs-item">
                            <span className="cs-package">All-You-Need Website</span>
                            <div className="cs-flex-wrapper">
                                <span className="cs-price">$149.50</span>
                                {/* <span className="cs-frequency">/Month</span> */}
                            </div>
                            <p className="cs-item-text">
                                Full online digital precense overhaul, featuring a website and Google Business Profile service and more. 
                            </p>
                            <a href="/contact" className="cs-button-solid cs-price-button">Get In Touch</a>
                        </li>
                        <li className="cs-item cs-popular">
                            <span className="cs-package">Premium Website</span>
                            <div className="cs-flex-wrapper">
                                <span className="cs-price">$999.50</span>
                                {/* <span className="cs-frequency">/Month</span> */}
                            </div>
                            <p className="cs-item-text">
                                Same as the All-You-Need plan, but includes A/B testing of your site weekly to optimise converting customers, Google and Facebook ad campaigns and more.
                            </p>
                            <a href="/contact" className="cs-button-solid cs-price-button">Get In Touch</a>
                        </li>
                        {/* <li className="cs-item">
                            <span className="cs-package">Monthly Pass</span>
                            <div className="cs-flex-wrapper">
                                <span className="cs-price">$149.99</span>
                                <span className="cs-frequency">/Month</span>
                            </div>
                            <p className="cs-item-text">
                                This month to month option allows you to workout up to four times a month. Reservation needed four timed.
                            </p>
                            <a href="#" className="cs-button-solid cs-price-button">Book Appointment</a>
                        </li> */}
                    </ul>
                </div>
            </div>
            {/* SVG Graphic */}
            <img
                className="cs-graphic cs-light"
                src="https://csimg.nyc3.cdn.digitaloceanspaces.com/Images/Graphics/white-texture.svg"
                alt="graphic"
                height="1920"
                width="483"
                loading="lazy"
                decoding="async"
                aria-hidden="true"
            />
            <img
                className="cs-graphic cs-dark"
                src="https://csimg.nyc3.cdn.digitaloceanspaces.com/Images/Graphics/dark-spatter.svg"
                alt="graphic"
                height="1920"
                width="483"
                loading="lazy"
                decoding="async"
                aria-hidden="true"
            />
        </section>
    );
};

export default PricingSection;
